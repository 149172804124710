let commons = require('../utils/commons');

function initMobileMenuLevelTwoDropdown(qaNode) {
    const mobileMenuLevelTwoDropdownElements = qaNode.getElementsByClassName('sub-menu-list-item-level-2');
    if (mobileMenuLevelTwoDropdownElements.length <= 0) {
        console.error('initMobileMenuLevelTwoDropdown: no mobileMenuLevelTwoDropdownElements found by class "sub-menu-list-item-level-2"');
        return false;
    }
    let closeAll = function () {
        for(let i = 0; i < mobileMenuLevelTwoDropdownElements.length; i++) {
            let mobileMenuLevelTwoDropdownElement = mobileMenuLevelTwoDropdownElements.item(i);
            commons.removeClassName(mobileMenuLevelTwoDropdownElement, 'open');
        }
    }
    let toggle = function(element) {
        if (commons.hasClassName(element, 'open')) {
            commons.removeClassName(element, 'open');
        } else {
            commons.addClassName(element, 'open');
        }
    }
    for(let i = 0; i < mobileMenuLevelTwoDropdownElements.length; i++) {
       let mobileMenuLevelTwoDropdownElement = mobileMenuLevelTwoDropdownElements.item(i);
       let linkOpen = mobileMenuLevelTwoDropdownElement.getElementsByTagName('a')[0];
       if (linkOpen === undefined || !linkOpen) {
           console.error('initMobileMenuLevelTwoDropdown: linkOpen not found by class "list-opener" in ', mobileMenuLevelTwoDropdownElement);
           return false;
       }
       linkOpen.addEventListener('click', function(e){
           e.preventDefault();
           toggle(mobileMenuLevelTwoDropdownElement);
       });
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const qaContainers = document.getElementsByClassName('sub-menu-list-level-2');
    for(let i = 0; i < qaContainers.length; i++) {
        let qaContainer = qaContainers.item(i);
        initMobileMenuLevelTwoDropdown(qaContainer);
    }
})
