let commons = require('../utils/commons');

function mobileMainMenuInit(menuNode, mobileMenuNode) {
    this.mobileMenuNode = mobileMenuNode;
    this.baseNode = menuNode;
    if (this.baseNode === undefined || !this.baseNode) {
        console.log('mobileMainMenuInit: no baseNode found');
        return false;
    }
    this.mainMenuItemsSub = this.baseNode.getElementsByClassName('have-submenu');
    // functions
    this.closeAllSubMenus = function() {
        commons.removeClassName(this.mobileMenuNode, 'mobile-offset');
        for(let i = 0; i < this.mainMenuItemsSub.length; i++) {
            let mainMenuItemSub = this.mainMenuItemsSub.item(i);
            commons.removeClassName(mainMenuItemSub, 'open');
            commons.addClassName(mainMenuItemSub, 'close');
        }
    }
    this.openSubMenu = function(mainMenuItemNode) {
        commons.addClassName(this.mobileMenuNode, 'mobile-offset');
        commons.removeClassName(mainMenuItemNode, 'close');
        commons.addClassName(mainMenuItemNode, 'open');
    }
    // events
    for(let i = 0; i < this.mainMenuItemsSub.length; i++) {
        let mainMenuItemSub = this.mainMenuItemsSub.item(i);
        let mainMenuItemSubLink = mainMenuItemSub.getElementsByClassName('main-link')[0];
        if (mainMenuItemSubLink === undefined || !mainMenuItemSubLink) {
            continue;
        }
        let closeSubMenuLinks = mainMenuItemSub.getElementsByClassName('close-sub-menu');
        for(let i = 0; i < closeSubMenuLinks.length; i++) {
            let closeSubMenuLink = closeSubMenuLinks.item(i);
            closeSubMenuLink.addEventListener('click', function(e) {
                e.preventDefault();
                this.closeAllSubMenus();
            }.bind(this));
        }
        mainMenuItemSubLink.addEventListener('click', function(e) {
            e.preventDefault();
            this.closeAllSubMenus();
            this.openSubMenu(mainMenuItemSub);
        }.bind(this));
    }
}

function mobileMenuInit() {
    const menuOpenLink = document.getElementById('mobile-menu-open-link');
    if (menuOpenLink === undefined || !menuOpenLink) {
        console.log('mobileMenuInit: no menuOpenLink found by id "mobile-menu-open-link"');
        return false;
    }
    const menuCloseLink = document.getElementById('mobile-menu-close');
    if (menuCloseLink === undefined || !menuCloseLink) {
        console.log('mobileMenuInit: no menuCloseLink found by id "mobile-menu-close"');
        return false;
    }
    const menuBodyNode = document.getElementsByClassName('menu-body')[0];
    if (menuBodyNode === undefined || !menuBodyNode) {
        console.log('mobileMenuInit: no menuBodyNode found by id "menu-body"');
        return false;
    }
    const menuBackgroundNode = document.getElementsByClassName('menu-background')[0];
    if (menuBackgroundNode === undefined || !menuBackgroundNode) {
        console.log('mobileMenuInit: no menuBackgroundNode found by id "menu-background"');
        return false;
    }
    this.toggleMenu = function() {
        // menu
        if (commons.hasClassName(menuBodyNode, 'closed')) {
            commons.addClassName(menuBodyNode, 'animated');
            commons.removeClassName(menuBodyNode, 'closed');
            setTimeout(function() {
                commons.removeClassName(menuBodyNode, 'animated');
            }, 350);
        } else {
            commons.addClassName(menuBodyNode, 'animated');
            setTimeout(function() {
                commons.addClassName(menuBodyNode, 'closed');
            }, 350);
        }
        // background
        if (commons.hasClassName(menuBackgroundNode, 'closed')) {
            commons.addClassName(menuBackgroundNode, 'animated');
            commons.removeClassName(menuBackgroundNode, 'closed');
            setTimeout(function() {
                commons.removeClassName(menuBackgroundNode, 'animated');
            }, 250);
        } else {
            commons.addClassName(menuBackgroundNode, 'animated');
            setTimeout(function() {
                commons.addClassName(menuBackgroundNode, 'closed');
            }, 250);
        }
    }
    menuOpenLink.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    menuCloseLink.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    //additional close buttons
    const addMenuCloseLinks = document.getElementsByClassName('add-mobile-menu-close');
    for(let i = 0; i < addMenuCloseLinks.length; i++) {
        let addMenuCloseLink = addMenuCloseLinks.item(i);
        addMenuCloseLink.addEventListener('click', function(e) {
            e.preventDefault();
            this.toggleMenu();
        }.bind(this));
    }
    //additional open buttons
    const addMenuOpenLinks = document.getElementsByClassName('add-mobile-menu-open');
    for(let i = 0; i < addMenuOpenLinks.length; i++) {
        let addMenuOpenLink = addMenuOpenLinks.item(i);
        addMenuOpenLink.addEventListener('click', function(e) {
            e.preventDefault();
            this.toggleMenu();
        }.bind(this));
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const mobileMenuobj = new mobileMenuInit();
    const mobileMenuNavNodes = document.getElementsByClassName('mobile-main-menu-nav');
    const menuBodyNode = document.getElementsByClassName('menu-body')[0];
    if (menuBodyNode === undefined || !menuBodyNode) {
        console.log('globalInit: no menuBodyNode found by id "menu-body"');
        return false;
    }
    for(let i = 0; i < mobileMenuNavNodes.length; i++) {
        let mobileMenuNavNode = mobileMenuNavNodes.item(i);
        let mobileMainMenuInitObj = new mobileMainMenuInit(mobileMenuNavNode, menuBodyNode);
    }
});
